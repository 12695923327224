<template>
    <zw-sidebar @shown="shown" :title="$t('stock.title.supply_stock_push')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row v-for="position in positions" :key="position.id">
                    <b-col cols="3">
                        {{ position.id }} {{ position.sku }} {{ position.name }}
                    </b-col>
                    <b-col cols="3">
                        <zw-input-group v-model="form.count[position.id]"
                                        name="count"
                                        :label-prefix="labelPrefix"
                                        type="number"
                                        validate="required"
                                        size="s"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="2">
                        <zw-switch-group v-model="form.exist[position.id]"
                                         name="exist"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="3">
                        <template v-if="form.exist[position.id]=='1'">
                            <zw-select-group
                                v-model="form.stock[position.id]"
                                :options="position.article_stocks"
                                text-field="label"
                                :label-prefix="labelPrefix"
                                value-field="id"
                                name="stocks"
                                size="s"
                            ></zw-select-group>
                        </template>
                        <template v-else>
                            <b-row>
                                <b-col cols="12">
                                    <zw-select-group v-model="form.warehouse[position.id]"
                                                     :options="getWarehouses()"
                                                     name="warehouse"
                                                     textField="name"
                                                     valueField="id"
                                                     :label-prefix="labelPrefix"
                                                     @input="warehouseSelected"
                                                     size="s"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="12">
                                    <zw-select-group v-model="form.warehouse_rack_num[position.id]"
                                                     v-if="form.warehouse[position.id]"
                                                     :options="racks[form.warehouse[position.id]] || []"
                                                     name="rack"
                                                     textField="label"
                                                     valueField="rack_num"
                                                     :label-prefix="labelPrefix"
                                                     @input="(val)=>{rackSelected(form.warehouse[position.id],val)}"
                                                     validate="required"
                                                     size="s"
                                    ></zw-select-group>
                                </b-col>
                                <b-col cols="12">
                                    <zw-select-group v-model="form.shelf_id[position.id]"
                                                     v-if="form.warehouse_rack_num[position.id] && shelves[form.warehouse[position.id]]"
                                                     :options="shelves[form.warehouse[position.id]][form.warehouse_rack_num[position.id]] || []"
                                                     name="shelve"
                                                     textField="label"
                                                     valueField="id"
                                                     :label-prefix="labelPrefix"
                                                     validate="required"
                                                     size="s"
                                    ></zw-select-group>
                                </b-col>
                            </b-row>
                        </template>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SupplyStockPushModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {
                id: null,
                count: {},
                stock: {},
                exist: {},
                warehouse: {},
                warehouse_rack_num: {},
                shelf_id: {},
            },
            labelPrefix: 'stock.label.',
            positions: [],
            racks: {},
            shelves: {},
        }
    },
    methods: {
        ...mapGetters('Stock', ['getWarehouses', 'getStock']),
        shown() {
            this.form.id = this.payload.id
            const warehouses = this.$store.dispatch('Stock/fetchWarehouses')
            window.axios.get(window.apiUrl + '/stock/supply-push/' + this.payload.id)
                .then(response => {
                    this.positions = response.data.positions
                    this.positions.forEach(position => {
                        this.$set(this.form.count, position.id, position.count)
                        if (position.article_stocks.length) {
                            this.$set(this.form.exist, position.id, '1')
                        }
                    })

                })
                .finally(() => {
                    this.loading = false
                })
        },
        warehouseSelected(warehouseId) {
            this.loading = true
            const racks = this.$store.dispatch('Stock/fetchRacks', {warehouseId})

            Promise.all([racks])
                .then((data) => {
                    this.$set(this.racks, warehouseId, data[0].map(rack => {
                        rack.label = rack.rack_name + ' ' + rack.rack_num
                        return rack
                    }))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        rackSelected(warehouseId, rackId) {
            this.loading = true
            const shelves = this.$store.dispatch('Stock/fetchShelves', {
                warehouseId,
                rackId,
                articleId: 0
            })

            Promise.all([shelves])
                .then((data) => {
                    if (!this.shelves[warehouseId]) {
                        this.$set(this.shelves, warehouseId, {})
                    }
                    this.$set(this.shelves[warehouseId], rackId, data[0].map(shelve => {
                        shelve.label = shelve.shelf_name + ' ' + shelve.shelf_num
                        return shelve
                    }))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.commonOnSubmit('Supply/supplyStockPush')
        },
    }
}
</script>